<template>
    <Outside>
        <div class="w-full p-6 flex flex-col justify-start items-center">
            <img class="w-24" src="/img/logos/logo_edem.svg" />
        </div>
        <form class="px-4 bg-bo-light-html rounded rounded-md text-gray-700 z-29"
              @submit.prevent="login">
            <div class="w-full p-4 flex flex-col justify-start items-center">
                <p class="text-lg font-semibold text-main">¡Hola!</p>
            </div>
            <div class="w-full p-4">
                <input type="email" class="form-input w-full px-4 text-center" v-model="form.email" placeholder="E-mail" autofocus required>
            </div>
            <div class="w-full p-4">
                <input @keydown="loginOnKeyDown" type="password" class="form-input w-full px-4 text-center tracking-widest leading-none"
                       v-model="form.password" placeholder="Contraseña" required>
            </div>

            <div class="w-full p-4 flex flex-col justify-center items-center">
                <Request model="Auth" ref="loginRef" action="login" :form="form" @success="onSuccess" @error="onError"/>
                <button type="submit"  class="focus:outline-none button-cust-default w-40 bg-yellow">Login</button>
            </div>

            <div class="w-full p-4 text-gray-600 text-center text-xs">
                <button @click="resetPassword" class="focus:outline-none">Restablece tu contraseña aquí</button>
            </div>
        </form>

        <!--Waves Container-->
        <div class="w-screen absolute bottom-0 left-0 right-0 z-0">
            <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g class="parallax">
                    <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(0,137,170,0.7" />
                    <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(0,137,170,0.5)" />
                    <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(0,137,170,0.3)" />
                    <use xlink:href="#gentle-wave" x="48" y="7" fill="#0089aa" />
                </g>
            </svg>
        </div>
        <!--Waves end-->


    </Outside>
</template>

<script>
import { Request } from '@/api/components';
import { actions } from '@/store';
import { User } from '@/api/models';
import Outside from "@/pages/outside/shared/Outside";

export default {
    name: 'Login',
    components: {
        Request, Outside
    },
    data() {
        return {
            form:{
                email: null,
                password: null,
            },
        };
    },
    methods: {
        login() {
            this.$refs.loginRef.request();
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        async onSuccess(response) {
            await actions.setToken(response.token);
            let user = await User.getAuthUser();
            await actions.setUser(user);
            this.$router.push({ name: 'Splash' });
        },
        resetPassword() {
            this.$router.push({ name: 'SendResetLink' });
        },
        loginOnKeyDown(e) {
            if (e.keyCode === 13) {
                this.login();
            }
        }
    },
}
</script>

